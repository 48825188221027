import * as React from "react";

import SEO from "../../components/seo";
import seoImage from "../../images/air-ambulance-edited.jpg";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import ArticleComponents, {
  TitleComponent,
  SubTitleComponent,
  ParagraphComponent,
  ParagraphHeaderComponent,
} from "../../components/article";
import { UnorderedList, ListItem, Tag } from "carbon-components-react";
import ExpertButtonComponent from "../../components/expertButton";

const title =
  "Ready to Submit Your Air Ambulance Claims Report? Reports are Due on 3/31/2023";
const subtitle =
  "Here’s a list of key requirements for health plans and TPAs to use as they start preparing to submit their first air ambulance claims report.";
const date = "January 10, 2023";
const author = "Mike Orth";

const AirAmbulanceArticle = () => {
  return (
    <>
      <SEO
        title={title}
        description={subtitle}
        image={{ src: seoImage, width: 1200, height: 627 }}
      />
      <header>
        <HeaderComponent />
      </header>
      <ExpertButtonComponent />
      <div className="leadSpaceMargin spacing09">
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-5"></div>
            <div className="bx--col-lg-11 bx--col-sm-0">
              <div className="articleHeader"></div>
            </div>
          </div>
          <div className="bx--row">
            <section className="bx--col-lg-3">
              <div className="articleInfoDesktop">
                <h5>Date</h5>
                <body>{date}</body>
              </div>
              <div className="articleInfoDesktop">
                <h5>Author</h5>
                <body>
                  <Tag type="blue" size="md" title="Author">
                    {author}
                  </Tag>
                </body>
              </div>
            </section>
            <div className="bx--col-lg-2"></div>
            <div className="bx--col-lg-9">
              <div className="articleInfoMobile">
                <div>
                  <h5>Date</h5>
                  <body>{date}</body>
                </div>
                <div style={{ paddingLeft: "1rem" }}>
                  <h5>Author</h5>
                  <body>
                    <Tag type="blue" size="md" title="Author">
                      {author}
                    </Tag>
                  </body>
                </div>
              </div>
              <TitleComponent title={title} />
              <SubTitleComponent subtitle={subtitle} />
              <img
                src={seoImage}
                alt="Air Ambulance"
                style={{ maxWidth: "100%" }}
                className="spacing07"
              />
              <ParagraphHeaderComponent header="Intro" />
              <ParagraphComponent
                paragraph={
                  <p className="articleParagraph">
                    While many health plans have rightly been focused on
                    complying with the new price transparency tool requirements
                    that went into effect on January 1, 2023{" "}
                    <a
                      href="https://www.cms.gov/healthplan-price-transparency"
                      target="_blank"
                    >
                      (part of the Transparency in Coverage rules)
                    </a>
                    , there is another compliance deadline fast approaching.
                  </p>
                }
              />
              <ParagraphComponent
                paragraph={
                  <p className="articleParagraph">
                    The No Surprises Act includes a lesser talked about
                    provision that requires{" "}
                    <a
                      href="https://www.cms.gov/newsroom/fact-sheets/air-ambulance-nprm-fact-sheet"
                      target="_blank"
                    >
                      health plans and air ambulance providers to submit data on
                      air ambulance claims
                    </a>{" "}
                    paid or incurred during calendar years 2022 and 2023.
                  </p>
                }
              />
              <ParagraphHeaderComponent header="Background" />
              <ParagraphComponent
                paragraph={
                  <p className="articleParagraph">
                    Specifically, for health plans, section 106(b) of the No
                    Surprises Act requires submission of a report to the Centers
                    for Medicare and Medicaid Services (CMS) that includes all
                    air ambulance claims incurred or paid during the previous
                    calendar year—and this submission must take place no later
                    than 90 days after the end of the year. For example, all air
                    ambulance claims incurred or paid in 2022 need to be
                    reported by 3/31/2023, and likewise, all 2023 claims need to
                    be reported by 3/30/2024.
                  </p>
                }
              />
              <ParagraphComponent
                paragraph={
                  <p className="articleParagraph">
                    This requirement is somewhat like the Prescription Drug Cost
                    Reporting (RxDC) from the standpoint that CMS is looking to
                    better understand the air ambulance marketplace—much like
                    they are looking to understand the prescription drug
                    marketplace with RxDC. After the two-year reporting period
                    concludes, the Secretary of Health and Human Services must
                    issue a report with their findings based on the data
                    submitted. The goal is to provide policymakers with better
                    insight into this part of the industry that typically leads
                    to high-dollar claims.
                  </p>
                }
              />
              <ParagraphHeaderComponent header="Latest Developments" />
              <ParagraphComponent
                paragraph={
                  <p className="articleParagraph">
                    The Federal departments (collectively the Departments of
                    Health and Human Services, Labor, and Treasury){" "}
                    <a
                      href="https://www.federalregister.gov/documents/2021/09/16/2021-19797/requirements-related-to-air-ambulance-services-agent-and-broker-disclosures-and-provider-enforcement"
                      target="_blank"
                    >
                      released proposed rules on September 16, 2021
                    </a>
                    , with comments due by October 18, 2021. It has been
                    crickets since then. It’s now January 2023 and we have still
                    not seen final rules or technical guidance specific to
                    health plans (air ambulance providers did receive some
                    limited guidance).{" "}
                    <a
                      href="https://www.reginfo.gov/public/do/DownloadDocument?objectID=114424601"
                      target="_blank"
                    >
                      You may download that guidance here.
                    </a>
                  </p>
                }
              />
              <ParagraphComponent
                paragraph={
                  <p className="articleParagraph">
                    The departments will need to release guidance soon so plans
                    and air ambulance providers can prepare and submit their
                    reports by the March 31 deadline. It’s also possible that
                    the deadline will be extended. For now, we can glean some
                    idea of what’s required through the proposed rules.
                  </p>
                }
              />
              <ParagraphHeaderComponent header="Key Requirements" />
              <ParagraphComponent
                paragraph={
                  <>
                    <p className="articleParagraph">
                      According to the proposed rules, for each air ambulance
                      claim received or paid during the previous calendar year,
                      a health plan, or their third-party administrator (TPA),
                      must provide the following data at the claim-level:
                    </p>
                    <>
                      <UnorderedList
                        className="articleParagraph"
                        style={{
                          paddingLeft: "3rem",
                        }}
                      >
                        <ListItem>NPI</ListItem>
                        <ListItem> Date of service</ListItem>
                        <ListItem>CPT/HCPCS</ListItem>
                        <ListItem>
                          Aircraft type (fixed wing or rotary)
                        </ListItem>
                        <ListItem> Pick-up and drop-off zip codes</ListItem>
                        <ListItem>
                          Whether the transport was emergent or non-emergent
                        </ListItem>
                        <ListItem>
                          Whether the transport was interfacility
                        </ListItem>
                        <ListItem>
                          Whether the transport originated in a rural or urban
                          area
                        </ListItem>
                        <ListItem>
                          Whether the transport provider was contracted
                        </ListItem>
                        <ListItem>
                          Whether the claim was paid, denied, or appealed
                        </ListItem>
                        <ListItem>Appeal outcome, if applicable</ListItem>
                        <ListItem> Denial reason code, if applicable</ListItem>
                        <ListItem> Billed charge</ListItem>
                        <ListItem> Member cost-share amount</ListItem>
                        <ListItem>
                          Type of delivery model: Hospital-owned or sponsored
                          program, municipality-sponsored program, hospital
                          independent partnership (hybrid) program, independent
                          program, or tribally operated program in Alaska *if
                          plans collect this data
                        </ListItem>
                      </UnorderedList>
                    </>
                  </>
                }
              />
              <ParagraphHeaderComponent header="What You Should be Doing to Prepare" />
              <ParagraphComponent
                paragraph={
                  <p className="articleParagraph">
                    Health plans should be asking their TPA if they are
                    preparing to comply with the new requirements. Likewise,
                    TPAs should be talking to their claims vendor if they
                    delegate claims processing. While we don't have final rules
                    yet, we generally know what the structure of the report will
                    look like based on the key requirements above. Ideally,
                    whichever claims software the plan or TPA uses, it will be
                    able to export this data with minimal customization.
                  </p>
                }
              />
              <ParagraphComponent
                paragraph={
                  <p className="articleParagraph">
                    Finally, self-funded plan sponsors must remember that they
                    are ultimately responsible for delivering the reporting on
                    time, even if they delegate to a TPA or another party to
                    submit on their behalf.
                  </p>
                }
              />
            </div>
            <div className="bx--col-lg-2"></div>
          </div>
        </div>
      </div>
      <footer>
        <FooterComponent />
      </footer>
    </>
  );
};

export default AirAmbulanceArticle;
